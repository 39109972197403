import React from 'react';
import Helmet, { HelmetProps } from 'react-helmet';
import { Location } from '@reach/router';

type Props = {
  /** Description text for the description meta tags */
  description?: string;
} & HelmetProps;

/**
 * An SEO component that handles all element in the head that can accept
 */
const SEO: React.FC<Props> = ({ children, description = '', title }) => {
  const metaDescription = description || 'BSCS HLIT';

  return (
    <Location>
      {({ location }) => (
        <Helmet title={title} titleTemplate="%s | BSCS HLIT" htmlAttributes={{lang: 'en-US'}}>
          <meta property="description" content={metaDescription} />

          {/* OG tags */}
          <meta
            property="og:url"
            content={process.env.GATSBY_SITE_URL + location.pathname}
          />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={metaDescription} />

          {children}
        </Helmet>
      )}
    </Location>
  );
};

export default SEO;
